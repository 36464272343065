<template>
  <!-- preview -->
  <div class="preview rounded-xl bg-black mt-4 relative rounded-xl overflow-hidden" :class="{'h-375px': showPicture}">
    <div class="video-preview bg-no-repeat bg-cover bg-center rounded-xl absolute top-0 right-0 bottom-0 left-0"
         :style="`background-image: url(${module.picture.getPath({w: 700, h: 400, crop: true})})`" v-if="showPicture"></div>
    <div class="absolute flex w-full h-full -m-4 cursor-pointer" v-if="hasVideo && showPicture">
      <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="71" height="71" viewBox="0 0 71 71">
        <g id="Group_29" data-name="Group 29" transform="translate(-770.058 -247.058)">
          <g id="Play_Pause" data-name="Play &gt; Pause" transform="translate(770.058 247.058)">
            <g id="Circle" fill="none" stroke="#fff" stroke-width="4">
              <circle cx="35.5" cy="35.5" r="35.5" stroke="none"/>
              <circle cx="35.5" cy="35.5" r="33.5" fill="none"/>
            </g>
            <g id="Icon" transform="translate(24.505 18.531)">
              <path id="Path_46" data-name="Path 46" d="M189.55,2688.1v33.581"
                    transform="translate(-189.55 -2688.03)" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-width="4"/>
              <path id="Path_47" data-name="Path 47" d="M218.679,2717.742l-29.012,17.083"
                    transform="translate(-189.6 -2701.115)" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-width="4"/>
              <path id="Path_48" data-name="Path 48" d="M189.654,2688.982l28.961,16.525"
                    transform="translate(-189.594 -2688.982)" fill="none" stroke="#fff" stroke-linecap="round"
                    stroke-width="4"/>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <video-player ref="video" v-if="hasVideo" @loaded="removePicture"></video-player>
  </div>
  <!-- end of preview -->
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import VideoPlayer from './video-player.vue';

@Component({
  name: 'module-preview',
  components: {
    VideoPlayer,
  },
  props: {
    module: {
      required: true,
    },
  },
  data() {
    return {
      showPicture: true,
    };
  },
  computed: {
    hasVideo() {
      return this.module.videoPreview !== null;
    },
  },
  methods: {
    removePicture() {
      this.showPicture = false;
    },
  },
  mounted() {
    if (!this.hasVideo) return;
    this.$nextTick(() => {
      const { video } = this.$refs;
      video.initialize(this.module.videoPreview);
    });
  },
})
export default class ModulePreview extends Vue {
}
</script>

<style scoped>

</style>
