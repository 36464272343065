<template>
  <div class="video-player-container" :class="{screen: loaded}">
    <video controls crossorigin playsinline ref="video"></video>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Hls from 'hls.js';

const plyrScriptUrl = 'https://lodylearn.b-cdn.net/script/plyr/plyr.min.js';

@Component({
  name: 'video-player',
  data() {
    return {
      player: null,
      loaded: false,
      playerWidth: 0,
      playerHeight: 0,
      ready: false,
      initParam: null,
    };
  },
  computed: {},
  methods: {
    initialize(m3u8) {
      if (!this.ready) {
        this.initParam = m3u8;
        return;
      }
      const { video } = this.$refs;
      video.src = m3u8;
      if (!Hls.isSupported() || video.canPlayType('application/vnd.apple.mpegurl')) {
        // eslint-disable-next-line no-undef
        this.player = new Plyr(video);
        this.loaded = true;
        this.$emit('loaded');
      } else {
        const hls = new Hls();
        hls.loadSource(m3u8);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const availableQualities = hls.levels.map((l) => l.height);
          const defaultOptions = {
            debug: false,
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
            settings: ['captions', 'quality', 'speed', 'loop'],
            quality: {
              default: Math.max(...availableQualities),
              options: availableQualities,
              forced: true,
              onChange: (e) => {
                hls.levels.forEach((level, levelIndex) => {
                  if (level.height === e) {
                    hls.currentLevel = levelIndex;
                  }
                });
              },
            },
          };
          // eslint-disable-next-line no-undef
          this.player = new Plyr(video, defaultOptions);
          this.player.once('canplay', () => {
            this.$emit('loaded');
            this.loaded = true;
          });
        });
        hls.attachMedia(video);
      }
    },
  },
  beforeDestroy() {
    document.head.querySelectorAll('[data-unload]').forEach((item) => {
      item.remove();
    });
  },
  mounted() {
    const script = document.createElement('script');
    script.setAttribute('src', plyrScriptUrl);
    script.setAttribute('data-unload', plyrScriptUrl);
    document.head.appendChild(script);
    script.addEventListener('load', () => {
      this.ready = true;
      if (this.initParam) {
        this.initialize(this.initParam);
      }
    });
  },
})
export default class VideoPlayer extends Vue {
}
</script>

<style scoped>
@import "https://lodylearn.b-cdn.net/script/plyr/plyr.css";
.video-player-container:not(.screen) {
  opacity: 0;
}
</style>
